import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { getModerationStatus } from "../utils/ModerationHelper";

function NotApplicableCheckbox(props) {
  const { question, setAnswerData, answer } = props;

  console.log("NotApplicableCheckbox answer", answer);

  const [isChecked, setIsChecked] = useState(answer?.data?.notApplicable ? answer?.data?.notApplicable : false);

  useEffect(() => {
    if (answer) {
      setIsChecked(answer?.data?.notApplicable ? answer?.data?.notApplicable : false);
    }
  }, [answer]);

  const inputChanged = (e) => {
    setIsChecked(e.target.checked);

    if (e.target.checked) {
      setAnswerData(
        question.id,
        {
          isComplete: true,
          data: {
            notApplicable: true,
          },
          meta: {
            notApplicable: {
              type: "boolean",
              moderationStatus: getModerationStatus(question.acceptable?.media, true, "needsreview"),
            },
          },
          // isUploading: false,
        },
        true,
        { collectClientData: true }
      );
    } else {
      setAnswerData(
        question.id,
        {
          isComplete: false,
          isUploading: false,
          data: null,
          meta: null
        },
        true,
        { collectClientData: true }
      );
    }
  };

  return (
    <div className="row justify-content-center mt-3">
      <div className="">
        <Form.Group
          className="question-form-checkbox form-group w-100 border bg-white rounded p-3"
          controlId="not-applicable"
        >
          <Form.Check
            id="not-applicable"
            name="not-applicable"
            type="checkbox"
            label="Not Applicable"
            onChange={inputChanged}
            checked={isChecked}
            className="fw-bold"
          />
        </Form.Group>
      </div>
    </div>
  );
}

export default NotApplicableCheckbox;
