import React, { useState } from "react";
import SurveyResponseService from "../services/SurveyResponseService";
import SurveyService from "../services/SurveyService";
import { autoId } from "../utils/StringHelpers";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

import logo from "../assets/active-comply-logo.svg";

const Start = (props) => {
  const [email, setEmail] = useState("");
  const [accountId, setAccountId] = useState("e6593184d34648e981c8fd8c84084a2f");
  const [existingSurveyResponseId, setExistingSurveyResponseId] = useState("");
  const [surveyId, setSurveyId] = useState("roi_e6593184d34648e981c8fd8c84084a2f");
  const [surveyUrl, setSurveyUrl] = useState("");

  const handleSendLink = async () => {
    if (existingSurveyResponseId) {
      let existingSurveyDoc = await SurveyResponseService.get(existingSurveyResponseId);

      handleSendSignInLinkToEmail(existingSurveyResponseId, existingSurveyDoc.token.id);
    } else {
      SurveyService.fetchSurvey(surveyId).then((survey) => {
        let newSurveyDoc = {
          id: autoId(),
          accountId: survey.accountId,
          surveyId: surveyId,
          surveyName: survey.name,
          uid: email,
          created: new Date().toISOString(),
          token: {
            id: autoId(),
            timestamp: new Date().toISOString(),
          },
        };

        SurveyResponseService.addSurvey(newSurveyDoc).then((response) => {
          handleSendSignInLinkToEmail(newSurveyDoc.id, newSurveyDoc.token.id);
        });
      });
    }
  };

  const handleSendSignInLinkToEmail = (surveyResponseId, tokenId) => {
    let localSurveyUrl = `${window.location.origin}/${surveyResponseId}/${tokenId}`;
    setSurveyUrl(localSurveyUrl);

    console.log("surveyUrl", localSurveyUrl);
  };

  const addUpdateSurvey = (filename) => {
    fetch(`/surveys/${filename}.json`)
      .then((response) => response.json())
      .then((json) => {
        json.accountId = accountId;
        json.id = `${json.id}_${accountId}`;
        setSurveyId(json.id)
        SurveyService.add(json);
        console.log("survey updated", json);
      });
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col className="col-md-10 col-lg-7 col-xl-6 mt-5">
          <img className="d-block img-fluid img-logo mt-4 mb-5 mx-auto" src={logo} alt="Active Comply" />
          <Form.Group className="mb-4" controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="name@example.com"
            />
          </Form.Group>
          <Form.Group className="mb-4" controlId="existingSurveyResponseId">
            <Form.Label>Existing Survey Response ID</Form.Label>
            <Form.Control
              type="text"
              disabled={!email || surveyId}
              value={existingSurveyResponseId}
              onChange={(e) => setExistingSurveyResponseId(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-4" controlId="newSurveyResponse">
            <Form.Label>Or New Survey Response Doc</Form.Label>
            <Form.Control
              type="text"
              placeholder="Survey ID"
              disabled={!email || existingSurveyResponseId}
              value={surveyId}
              onChange={(e) => setSurveyId(e.target.value)}
            />
          </Form.Group>
          <div className="mb-4 d-flex justify-content-center">
            <button
              onClick={handleSendLink}
              disabled={!email || (!existingSurveyResponseId && !surveyId)}
              className="app-btn-secondary btn w-100"
            >
              Generate Link
            </button>
          </div>

          <div className="mb-4">
            <Form.Group className="mb-4" controlId="accountId">
              <Form.Label>Account ID</Form.Label>
              <Form.Control type="text" value={accountId} onChange={(e) => setAccountId(e.target.value)} />
            </Form.Group>
          </div>

          <div className="mb-4 d-flex flex-column">
            <button onClick={() => addUpdateSurvey("cleandeskaudit_prosperity")} className="btn btn-outline-primary btn-pill m-2">
              Add Clean Desk Audit (Prosperity)
            </button>
            <button onClick={() => addUpdateSurvey("remarketing_field_review_vanderbilt")} className="btn btn-outline-primary btn-pill m-2">
              Add Remarketing Field Review (Vanderbilt)
            </button>
            <button onClick={() => addUpdateSurvey("listing_property_audit_phm")} className="btn btn-outline-primary btn-pill m-2">
              Add Listing/Property Audit (PHM)
            </button>
            <button onClick={() => addUpdateSurvey("asa_in_person_audit_phm_v2")} className="btn btn-outline-primary btn-pill m-2">
              Add ASA In Person Audit (PHM)
            </button>
            <button onClick={() => addUpdateSurvey("roi_nmls")} className="btn btn-outline-primary btn-pill m-2">
              Add ROI NMLS Survey
            </button>
            <button onClick={() => addUpdateSurvey("roi_non_nmls")} className="btn btn-outline-primary btn-pill m-2">
              Add ROI Non-NMLS Survey
            </button>
            <button onClick={() => addUpdateSurvey("roi_emapta")} className="btn btn-outline-primary btn-pill m-2">
              Add ROI Emapta
            </button>
            <button onClick={() => addUpdateSurvey("roi_tmac")} className="btn btn-outline-primary btn-pill m-2">
              Add ROI TMAC
            </button>
            <button onClick={() => addUpdateSurvey("roi_towne")} className="btn btn-outline-primary btn-pill m-2">
              Add ROI Towne
            </button>
            <button onClick={() => addUpdateSurvey("branchaudit")} className="btn btn-outline-primary btn-pill m-2">
              Add Branch Audit Survey (Demo)
            </button>
            <button onClick={() => addUpdateSurvey("branchaudit_v2")} className="btn btn-outline-primary btn-pill m-2">
              Add Branch Audit Survey
            </button>
            <button onClick={() => addUpdateSurvey("branchaudit_prosperity")} className="btn btn-outline-primary btn-pill m-2">
              Add Branch Audit Survey (Prosperity)
            </button>
            <button onClick={() => addUpdateSurvey("branchaudit_my_mortgage")} className="btn btn-outline-primary btn-pill m-2">
              Add Branch Audit Survey (My Mortgage)
            </button>
            <button onClick={() => addUpdateSurvey("branchaudit_nrl")} className="btn btn-outline-primary btn-pill m-2">
              Add Branch Audit Survey (NRL)
            </button>
            <button onClick={() => addUpdateSurvey("branchaudit_usamortgagedas")} className="btn btn-outline-primary btn-pill m-2">
              Add Branch Audit Survey (USA Mortgage DAS)
            </button>
            <button onClick={() => addUpdateSurvey("branchaudit_vanderbilt")} className="btn btn-outline-primary btn-pill m-2">
              Add Branch Audit Survey (Vanderbilt Mortgage & Finance)
            </button>
            <button onClick={() => addUpdateSurvey("branchaudit_cmg_v3")} className="btn btn-outline-primary btn-pill m-2">
              Add Branch Audit Survey v3 (CMG Mortgage Inc.)
            </button>
            <button onClick={() => addUpdateSurvey("branchaudit_revolution")} className="btn btn-outline-primary btn-pill m-2">
              Add Branch Audit Survey (Revolution Mortgage)
            </button>
            <button onClick={() => addUpdateSurvey("branchaudit_FBC")} className="btn btn-outline-primary btn-pill m-2">
              Add Branch Audit Survey (FBC Mortgage)
            </button>
            <button onClick={() => addUpdateSurvey("persona_perfect_v1")} className="btn btn-outline-primary btn-pill m-2">
              Add Persona Perfect V1
            </button>
            <button onClick={() => addUpdateSurvey("test_survey")} className="btn btn-outline-primary btn-pill m-2">
              Add Test Survey
            </button>
            <button onClick={() => addUpdateSurvey("roi_simple_demo")} className="btn btn-outline-primary btn-pill m-2">
              Add Simple Demo Survey
            </button>
            <button onClick={() => addUpdateSurvey("location_only")} className="btn btn-outline-primary btn-pill m-2">
              Add Location-Only Survey
            </button>
            <button onClick={() => addUpdateSurvey("video_only")} className="btn btn-outline-primary btn-pill m-2">
              Add Video-Only Survey
            </button>
            <button onClick={() => addUpdateSurvey("photo_only")} className="btn btn-outline-primary btn-pill m-2">
              Add Photo-Only Survey
            </button>
            <button onClick={() => addUpdateSurvey("branchaudit_das")} className="btn btn-outline-primary btn-pill m-2">
            Add branchaudit_das Survey
            </button>
            <button onClick={() => addUpdateSurvey("roi_das")} className="btn btn-outline-primary btn-pill m-2">
              Add roi_das Survey
            </button>
            <button onClick={() => addUpdateSurvey("roi_nmls_das")} className="btn btn-outline-primary btn-pill m-2">
              Add roi_nmls_das Survey
            </button>
            <button onClick={() => addUpdateSurvey("branchaudit_PRMG")} className="btn btn-outline-primary btn-pill m-2">
              Add branchaudit PRMG
            </button>
            <button onClick={() => addUpdateSurvey("branchaudit_lennar_mortgage")} className="btn btn-outline-primary btn-pill m-2">
             Add branchaudit Lennar Mortgage
            </button>
            <button onClick={() => addUpdateSurvey("roi_PRMI")} className="btn btn-outline-primary btn-pill m-2">
             Add roi_PRMI
            </button>
            <button onClick={() => addUpdateSurvey("branchaudit_southwest")} className="btn btn-outline-primary btn-pill m-2">
             Add branchaudit_southwest
            </button>
            <button onClick={() => addUpdateSurvey("roi_southwest")} className="btn btn-outline-primary btn-pill m-2">
             Add roi_southwest
            </button>
            <button onClick={() => addUpdateSurvey("roi_southwest_alternate")} className="btn btn-outline-primary btn-pill m-2">
             Add roi_southwest_alternate
            </button>
          </div>
          {surveyUrl && (
            <div className="small text-muted text-center">
              {/* <div>{email}</div>
            <div>Survey ID: {surveyId}</div> */}
              <Card className="shadow-sm border-0 p-3 mt-4 mb-5">
                <a className="fw-600" href={surveyUrl}>
                  {surveyUrl}
                </a>
              </Card>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Start;
