import React, { useMemo, useEffect, useState, useRef, useContext } from "react";
import Photo from "./Photo";
import Video from "./Video";
import Info from "./Info";
import FormInputs from "./FormInputs";
import SpeedTest from "./SpeedTest";
import Location from "./Location";
import surveyResponseService from "../services/SurveyResponseService";
import { PageContext } from "../context/PageContext";

function Question(props) {
  const { question, surveyResponse, handleAction, surveyResponseId, linkedDevice, setAnswerData } = props;

  const [, dispatch] = useContext(PageContext);

  const [answer, setAnswer] = useState();
  const unsubAnswersDoc = useRef();

  useEffect(() => {}, [answer]);

  useEffect(() => {
    if (!question || !surveyResponse) {
      return;
    }

    let isMounted = true;

    const fetchAnswer = async (questionId, surveyResponseId) => {
      let unsub = await surveyResponseService.listenAnswer(
        surveyResponseId,
        questionId,
        async (surveyResponseAnswerDoc) => {
          if (!isMounted) {
            return;
          }

          if (!surveyResponseAnswerDoc.exists()) {
            console.log("answer missing... adding ", questionId);
            surveyResponseService.addAnswer(surveyResponseId, questionId, {
              id: questionId,
              accountId: surveyResponse.accountId,
              isComplete: question.type === "info" ? true : false,
              surveyResponseId: surveyResponseId,
              created: new Date().toISOString(),
            });
          } else {
            console.log("answer received", questionId, surveyResponseAnswerDoc.data());

            let localAnswer = surveyResponseAnswerDoc.data();

            dispatch({
              type: "ANSWER_LOADED",
              payload: {
                fullscreen: false,
                showSurveyNav: true,
                darkBackground: false,
                showProgressBar: true,
                answer: { ...localAnswer },
              },
            });

            //if (localAnswer.isComplete) {
            setAnswerData(question.id, {
              isComplete: localAnswer.isComplete,
            });
            //}

            setAnswer(localAnswer);
          }
        }
      );

      return unsub;
    };

    fetchAnswer(question.id, surveyResponse.id)
      .then((unsub) => {
        unsubAnswersDoc.current = unsub;
      })
      .catch(console.error);

    //return this so that react will clean it up when unmounted
    return function cleanup() {
      isMounted = false;

      if (unsubAnswersDoc.current) {
        console.log("cleaning up answer sub", question.id, surveyResponse.id);
        unsubAnswersDoc.current();
      }
    };
  }, [dispatch, question, setAnswerData, surveyResponse]);

  return useMemo(() => {
    let questionProps = {
      answer: answer,
      question: question,
      handleAction: handleAction,
      linkedDevice: linkedDevice,
      surveyResponse: surveyResponse,
      surveyResponseId: surveyResponseId,
      setAnswerData: setAnswerData,
    };

    let components = {
      photo: <Photo {...questionProps} />,
      video: <Video {...questionProps} />,
      info: <Info {...questionProps} />,
      speed_test: <SpeedTest {...questionProps} />,
      form: <FormInputs {...questionProps} />,
      location: <Location {...questionProps} />,
    };

    return components[question.type];
  }, [answer, question, handleAction, linkedDevice, surveyResponse, surveyResponseId, setAnswerData]);
}

export default Question;
