import { doc, setDoc, collection, getDoc } from "firebase/firestore";
import { autoId } from "../utils/StringHelpers";

import { db } from "./Firebase";

const surveysCollectionRef = collection(db, "surveys");

const SurveyService = {
  fetchSurvey: async (surveyId) => {
    try {
      console.log("service fetch survey id " + surveyId);
      const surveysDocSnap = await getDoc(doc(surveysCollectionRef, surveyId));
      return surveysDocSnap.data();
    } catch (e) {
      console.error(`Survey not found: ${e}`);
    }
  },
  add: async (data) => {
    try {
      if (!data.id) {
        data.id = autoId();
      }
      else {
        data.lastModified = new Date().toISOString();
      }

      if (!data.created) {
        data.created = new Date().toISOString();
      }

      const surveyDocRef = await setDoc(doc(surveysCollectionRef, data.id), data);

      return surveyDocRef;
    } catch (error) {
      console.error("add survey error", error);
    }
  },
};

export default SurveyService;
