import { useEffect, useCallback } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { getModerationStatus } from "../../utils/ModerationHelper";

function SelectInput(props) {
  const { inputDefaultValue, inputFieldDef, inputFieldId, setInputData, focus, disabled } = props;

  const {
    register,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onBlur", // "onChange"
  });

  const inputChanged = (e) => {
    trigger(inputFieldId).then((isValid) => {
      sendInputData(inputFieldId, e.target.value, isValid);
    });
  };

  const sendInputData = useCallback(
    (id, value, isValid) => {
      value = value?.trim() ?? "";

      let inputData = {
        id: id,
        data: value,
        isValid: isValid && !disabled,
      };

      const acceptableOperator =
        (inputFieldDef.acceptable?.value?.hasOwnProperty("eq") && "eq") ||
        (inputFieldDef.acceptable?.value?.hasOwnProperty("neq") && "neq");

      const acceptableValue = inputFieldDef.acceptable?.value[acceptableOperator];

      const isAcceptable = (operator) => {
        const regex = new RegExp(acceptableValue);
        if (value !== "") {
          if (operator === "eq") return !!value.match(regex);
          if (operator === "neq") return !value.match(regex);
        }
        return null;
      };

      inputData.meta = {
        isAcceptable: isAcceptable(acceptableOperator),
        type: "string",
      };

      inputData.meta.moderationStatus = getModerationStatus(
        inputFieldDef.acceptable,
        inputData.meta.isAcceptable,
        inputFieldDef.acceptable?.value?.moderationStatus?.default
      );

      setInputData(inputData);
    },
    [setInputData, disabled, inputFieldDef?.acceptable]
  );

  useEffect(() => {
    console.log("@textinput", inputDefaultValue, inputFieldId);

    trigger(inputFieldId).then((isValid) => {
      sendInputData(inputFieldId, inputDefaultValue, isValid);
      clearErrors(inputFieldId);
    });

    // return function cleanup() {
    //   console.log("@cleaning");
    // };
  }, [inputDefaultValue, inputFieldId, clearErrors, sendInputData, trigger]);

  return (
    <Form.Group className="question-form-input form-group w-100 mb-4" controlId={props.inputFieldId}>
      <Form.Label className="fs-6 mb-1 fw-bold">{props.inputFieldDef.text?.title}</Form.Label>

      <Form.Select
        autoFocus={focus}
        defaultValue={inputDefaultValue}
        disabled={disabled}
        isInvalid={errors[props.inputFieldId]}
        {...register(props.inputFieldId, {
          onChange: (e) => {
            inputChanged(e);
          },
          required: props.inputFieldDef.required,
        })}
      >
        {props.inputFieldDef.options.map((option, index) => {
          return <option key={`option-${index}`} value={option.value}>{option?.title}</option>
        })}
      </Form.Select>

      {errors[props.inputFieldId]?.type === ("required" || "pattern") && (
        <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
      )}
      {props.text?.explain && <Form.Text className="text-muted">{props.text?.explain}</Form.Text>}
    </Form.Group>
  );
}

export default SelectInput;
