// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Moved config to .env file for security and in case we need local development
const firebaseConfig = {
  apiKey: window.appsettings.REACT_APP_FIREBASE_API_KEY,
  authDomain: window.appsettings.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: window.appsettings.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: window.appsettings.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: window.appsettings.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: window.appsettings.REACT_APP_FIREBASE_APP_ID,
  measurementId: window.appsettings.REACT_APP_FIREBASE_MEASUREMENT_ID,
  serviceAccountId: window.appsettings.REACT_APP_FIREBASE_SERVICE_ACCOUNT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);

// connectFirestoreEmulator(db, "127.0.0.1", 8081);
// connectFunctionsEmulator(functions, "127.0.0.1", 5001);
// connectAuthEmulator(auth, "http://127.0.0.1:9099");

console.log("environment", process.env.NODE_ENV);

export const authenticateSurvey = httpsCallable(functions, "authenticateSurvey");
