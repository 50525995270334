import React, { useEffect, useCallback, useState } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { getModerationStatus } from "../../utils/ModerationHelper";

function RadioInput(props) {
  const { inputDefaultValue, inputFieldDef, inputFieldId, setInputData, disabled } = props;

  const [touched, setTouched] = useState(false);
  const [value, setValue] = useState(inputDefaultValue);

  const {
    register,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValue: inputDefaultValue,
  });

  const inputChanged = (e) => {
    trigger(inputFieldId).then((isValid) => {
      sendInputData(inputFieldId, e.target.value, isValid, true);
      setValue(e.target.value);
    });
    setTouched(true);
  };

  const isChecked = (id) => {
    return inputDefaultValue === id;
  };

  const sendInputData = useCallback(
    (id, value, isValid, isTouched) => {
      let inputData = {
        id: id,
        data: value,
        isValid: isValid,
        touched: isTouched ? isTouched : false,
        meta: {},
      };

      const acceptableOperator =
        (inputFieldDef.acceptable?.value?.hasOwnProperty("eq") && "eq") ||
        (inputFieldDef.acceptable?.value?.hasOwnProperty("neq") && "neq");
      const acceptableValue = inputFieldDef.acceptable?.value[acceptableOperator];

      const isAcceptable = (operator) => {
        if (operator === "eq") return acceptableValue === value;
        if (operator === "neq") return acceptableValue !== value;
        return true;
      };

      inputData.meta = {
        isAcceptable: isAcceptable(acceptableOperator),
        type: "string",
      };

      inputData.meta.moderationStatus = getModerationStatus(inputFieldDef.acceptable, inputData.meta.isAcceptable);

      setInputData(inputData);
    },
    [inputFieldDef.acceptable, setInputData]
  );

  useEffect(() => {
    trigger(inputFieldId).then((isValid) => {
      sendInputData(inputFieldId, value, isValid);
    });
  }, [inputDefaultValue, value, inputFieldId, sendInputData, trigger]);

  if (props.inputFieldDef) {
    return (
      <Form.Group className="question-form-radio form-group w-100" controlId={props.inputFieldId}>
        <Form.Label className="fs-6 mb-1 fw-bold">{props.inputFieldDef.text?.title}</Form.Label>
        <div className="radio-select-wrap rounded border">
          <div className="scroll-viewport p-3">

          {props.inputFieldDef.text?.explain && (
            <ReactMarkdown className="markdown" linkTarget="_blank">{props.inputFieldDef.text?.explain}</ReactMarkdown>
          )}

          {props.inputFieldDef.options.map((option, index) => {
            return (
              <Form.Check
                key={`option-${index}`}
                type={props.inputFieldDef.type}
                id={`radio-id_${props.inputFieldId}_${option.value}`}
                value={option.value}
                name={props.inputFieldId}
                label={option?.title}
                defaultChecked={isChecked(option.value)}
                disabled={disabled}
                feedback="Answer is required"
                feedbackType="invalid"
                className="fw-bold"
                isInvalid={errors[props.inputFieldId]?.type === "required" && touched}
                {...register(props.inputFieldId, {
                  onChange: (e) => {
                    inputChanged(e);
                  },
                  required: inputFieldDef.required,
                })}
              />
            );
          })}
          </div>
        </div>
      </Form.Group>
    );
  } else {
    return <div>loading...</div>;
  }
}

export default RadioInput;
