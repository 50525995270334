import { useEffect, useCallback, useState } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { getModerationStatus } from "../../utils/ModerationHelper";

function MultiSelectInput(props) {
  // const [touched, setTouched] = useState(false);
  const { inputDefaultValue, inputFieldDef, inputFieldId, setInputData, disabled } = props;

  const [checkedBoxes, setCheckedBoxes] = useState(inputDefaultValue || []);

  const {
    register,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onBlur", // "onChange"
  });

  // const inputChanged = (e) => {
  //   trigger(inputFieldId).then((isValid) => {
  //     sendInputData(inputFieldId, [...e.target.selectedOptions].map(item => item.value), isValid);
  //   });
  // };

  const inputChanged = (e) => {
    if (e.target.checked) {
      if (!checkedBoxes.includes(e.target.value)) {
        checkedBoxes.push(e.target.value);
      }
    } else {
      if (checkedBoxes.includes(e.target.value)) {
        checkedBoxes.splice(checkedBoxes.indexOf(e.target.value), 1);
      }
    }
    setCheckedBoxes(checkedBoxes.map((x) => x));
    sendInputData(inputFieldId, checkedBoxes.map((x) => x), checkedBoxes.length > 0);

    // setTouched(true);
  };


  const sendInputData = useCallback(
    (id, value, isValid) => {
      // value = value?.trim() ?? "";

      let inputData = {
        id: id,
        data: value,
        isValid: isValid && !disabled,
      };

      // const acceptableOperator =
      //   (inputFieldDef.acceptable?.value?.hasOwnProperty("eq") && "eq") ||
      //   (inputFieldDef.acceptable?.value?.hasOwnProperty("neq") && "neq");

      // const acceptableValue = inputFieldDef.acceptable?.value[acceptableOperator];

      // const isAcceptable = (operator) => {
      //   const regex = new RegExp(acceptableValue);
      //   if (value !== "") {
      //     if (operator === "eq") return !!value.match(regex);
      //     if (operator === "neq") return !value.match(regex);
      //   }
      //   return null;
      // };

      inputData.meta = {
        // isAcceptable: isAcceptable(acceptableOperator),
        isAcceptable: (value?.length??0) > 0,
        type: "array",
      };

      inputData.meta.moderationStatus = getModerationStatus(
        inputFieldDef.acceptable,
        inputData.meta.isAcceptable,
        inputFieldDef.acceptable?.value?.moderationStatus?.default
      );

      setInputData(inputData);
    },
    [setInputData, disabled, inputFieldDef?.acceptable]
  );

  useEffect(() => {
    console.log("@textinput", inputDefaultValue, inputFieldId);

    trigger(inputFieldId).then((isValid) => {
      sendInputData(inputFieldId, inputDefaultValue, isValid);
      clearErrors(inputFieldId);
    });

    // return function cleanup() {
    //   console.log("@cleaning");
    // };
  }, [inputDefaultValue, inputFieldId, clearErrors, sendInputData, trigger]);

  const isChecked = (optionValue) => {
    if (!checkedBoxes) {
      return false;
    }

    return checkedBoxes.indexOf(optionValue) > -1;
  };

  return (
    <Form.Group className="question-form-input form-group w-100 mb-4" controlId={props.inputFieldId}>
      <Form.Label className="fs-6 mb-1 fw-bold">{props.inputFieldDef.text?.title}</Form.Label>

      <div className="multi-select-wrap rounded border">
        <div className="scroll-viewport p-3">
          <Form.Check
            id="select-all"
            name="select-all"
            type="checkbox"
            label="Select All"
            onChange={() => {
              if (inputFieldDef.options.length !== checkedBoxes.length) {
                setCheckedBoxes(inputFieldDef.options.map(option => option.value))
                sendInputData(inputFieldId, inputFieldDef.options.map(option => option.value), true);
                }
              else {
                setCheckedBoxes([])
                sendInputData(inputFieldId, [], false);
              }
            }}
            value="select-all"
            checked={inputFieldDef.options.length === checkedBoxes.length}
            className="fw-600"
          />
            {inputFieldDef.options.map((option, index) => {
              return (
                <Form.Check
                  key={`option-${index}`}
                  id={`option-${index}`}
                  name={`option-${index}`}
                  type="checkbox"
                  label={option?.title}
                  value={option.value}
                  checked={isChecked(option.value)}
                  feedbackType="invalid"
                  className="fw-600"
                  {...register(inputFieldId, {
                    onChange: (e) => {
                      inputChanged(e);
                    },
                    required: inputFieldDef.required,
                  })}
                />
              );
          })}
        </div>
      </div>

      {errors[props.inputFieldId]?.type === ("required" || "pattern") && (
        <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
      )}
      {props.text?.explain && <Form.Text className="text-muted">{props.text?.explain}</Form.Text>}
    </Form.Group>
  );
}

export default MultiSelectInput;
