import { doc, updateDoc, onSnapshot, collection, setDoc, getDoc, getDocs, writeBatch } from "firebase/firestore";
import { db } from "./Firebase";
import { autoId } from "../utils/StringHelpers";

const surveyResponsesCollectionRef = collection(db, "survey_responses");

const SurveyResponseService = {
  get: async (surveyResponseId) => {
    // console.log("service fetch surveyResponse for surveyResponseid " + surveyResponseId);
    const surveyResponseSnap = await getDoc(doc(surveyResponsesCollectionRef, surveyResponseId));
    return surveyResponseSnap.data();
  },

  listen: async (surveyResponseId, callback) => {
    try {
      // console.log("service fetch surveyResponse for surveyResponseid " + surveyResponseId);
      const surveyResponseDocRef = doc(surveyResponsesCollectionRef, surveyResponseId);
      return onSnapshot(surveyResponseDocRef, callback);
    } catch (e) {
      console.error(`Survey response error: ${e}`);
    }
  },

  update: (surveyResponseId, surveyResponse) => {
    const surveyResponseDocRef = doc(surveyResponsesCollectionRef, surveyResponseId);
    surveyResponse.lastModified = new Date().toISOString();
    updateDoc(surveyResponseDocRef, surveyResponse);
  },

  listenAnswer: async (surveyResponseId, questionId, callback) => {
    try {
      // console.log(`service fetch surveyResponseAnswer for surveyResponseid ${surveyResponseId} questionId ${questionId}`);

      const surveyResponseDocRef = doc(surveyResponsesCollectionRef, surveyResponseId);
      const answersRef = collection(surveyResponseDocRef, "answers");
      const answerDocRef = doc(answersRef, questionId);
      return onSnapshot(answerDocRef, callback);
    } catch (e) {
      console.error(`Survey response error: ${e}`);
    }
  },

  getAnswer: async (surveyResponseId, questionId) => {
    const surveyResponseDocRef = doc(surveyResponsesCollectionRef, surveyResponseId);
    const answersRef = collection(surveyResponseDocRef, "answers");
    const answerSnap = await getDoc(doc(answersRef, questionId));
    return answerSnap.data();
  },

  getAnswers: async (surveyResponseId) => {
    const surveyResponseDocRef = doc(surveyResponsesCollectionRef, surveyResponseId);
    const answersRef = collection(surveyResponseDocRef, "answers");
    const answerSnap = await getDocs(answersRef);
    const answers = {};
    answerSnap.forEach((doc) => {
      answers[doc.id] = doc.data();
    });
    return answers;
  },

  addAnswer: (surveyResponseId, questionId, answer) => {
    const surveyResponseDocRef = doc(surveyResponsesCollectionRef, surveyResponseId);
    const answersRef = collection(surveyResponseDocRef, "answers");
    const answerDocRef = doc(answersRef, questionId);
    setDoc(answerDocRef, answer, { merge: true });
  },


  updateAnswers: async (surveyResponseId, answers) => {
    const batch = writeBatch(db);

    const surveyResponseDocRef = doc(surveyResponsesCollectionRef, surveyResponseId);

    const answersRef = collection(surveyResponseDocRef, "answers");

    Object.keys(answers).forEach((answer) => {
      batch.update(doc(answersRef, answer), answers[answer]);
    });
    await batch.commit();
  },

  updateAnswer: (surveyResponseId, questionId, answer) => {
    const surveyResponseDocRef = doc(surveyResponsesCollectionRef, surveyResponseId);
    const answersRef = collection(surveyResponseDocRef, "answers");
    const answerDocRef = doc(answersRef, questionId);
    answer.lastModified = new Date().toISOString();
    updateDoc(answerDocRef, answer);
  },

  addSurvey: async (data) => {
    try {
      if (!data.id) {
        data.id = autoId();
      }

      const surveyResponseDocRef = await setDoc(doc(surveyResponsesCollectionRef, data.id), data);

      return surveyResponseDocRef;
    } catch (error) {
      console.error("add survey error", error);
    }
  },
};

export default SurveyResponseService;
