import SurveyResponseService from "../services/SurveyResponseService";
import SurveyService from "../services/SurveyService";
import { autoId } from "../utils/StringHelpers";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const StartSurvey = (props) => {
  let params = useParams(); // url params

  const initializeSurvey = async () => {
    return SurveyService.fetchSurvey(params.surveyId).then((survey) => {
      let newSurveyDoc = {
        id: autoId(),
        accountId: survey.accountId,
        surveyName: survey.name,
        surveyId: survey.id,
        created: new Date().toISOString(),
        token: {
          id: autoId(),
          timestamp: new Date().toISOString(),
        },
      };

      newSurveyDoc.uid = newSurveyDoc.id;

      return SurveyResponseService.addSurvey(newSurveyDoc)
        .then((response) => {
          return startSurvey(newSurveyDoc.id, newSurveyDoc.token.id);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  const startSurvey = (surveyResponseId, tokenId) => {
    let localSurveyUrl = `/${surveyResponseId}/${tokenId}`;
    console.log("surveyUrl", localSurveyUrl);
    return localSurveyUrl;
  };

  useEffect(() => {
    initializeSurvey().then((url) => {
      window.location = url;
    });
  });

  return <div></div>;
};

export default StartSurvey;
