import React, { useState, useEffect } from "react";
import QRCode from "qrcode";

function DisplayQRCode(props) {
  const { linkUrl, question } = props;

  const [qrCode, setQRCode] = useState();

  useEffect(() => {
    QRCode.toDataURL(linkUrl, { scale: 8 }).then((response) => setQRCode(response));
    return QRCode;
  }, [linkUrl]);

  return (
    <div className="text-center">
      <img className="rounded-3" src={qrCode} alt="QR code" />
      {question?.text?.explainQR && <p className="mt-3 mb-0">{question.text.explainQR}</p>}
    </div>
  );
}

export default DisplayQRCode;
