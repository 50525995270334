import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import QuestionHeader from "./QuestionHeader";
import { getModerationStatus } from "../utils/ModerationHelper";

function SpeedTest(props) {
  const { answer, question, setAnswerData } = props;
  // console.log("question", question);

  const [runSpeedtest, setRunSpeedtest] = useState(true);

  useEffect(() => {
    setRunSpeedtest(!(answer?.data && answer?.isComplete));
  }, [answer]);

  const kbpsToMbps = (kbps) => Math.round((kbps / 1000) * 10) / 10;

  useEffect(() => {
    function attachToWindow(listener) {
      // Cross-browser event listener for Chrome, Firefox, etc
      if (window.addEventListener) {
        window.addEventListener("message", listener);
      } // IE backwards compatibility
      else if (window.attachEvent) {
        window.attachEvent("onmessage", listener);
      }
    }
    function testCompleted(event) {
      if (event.origin !== "https://activecomply.speedtestcustom.com") {
        return;
      }

      const acceptableDownload = question.acceptable?.download;
      const acceptableUpload = question.acceptable?.upload;
      const isDownloadAcceptable = acceptableDownload ? acceptableDownload.gte <= event.data.download : false;
      const isUploadAcceptable = acceptableUpload ? acceptableUpload.gte <= event.data.upload : false;

      setAnswerData(
        question.id,
        {
          meta: {
            download: {
              type: "number",
              isAcceptable: isDownloadAcceptable,
              moderationStatus: getModerationStatus(acceptableDownload, isDownloadAcceptable),
            },
            upload: {
              type: "number",
              isAcceptable: isUploadAcceptable,
              moderationStatus: getModerationStatus(acceptableUpload, isUploadAcceptable),
            },
          },
          data: {
            download: event.data.download,
            upload: event.data.upload,
            downloadMbps: kbpsToMbps(event.data.download),
            uploadMbps: kbpsToMbps(event.data.upload),
            latency: event.data.latency,
          },
          isComplete: true,
        },
        true,
        { collectClientData: true }
      );
    }

    attachToWindow(testCompleted);

    // Send the initial or current state
    setAnswerData(question.id, {
      isComplete: answer && answer.isComplete !== undefined ? answer.isComplete : false,
    });

    return () => window.removeEventListener("message", testCompleted);
  }, [setAnswerData, answer, question]);

  return (
    <>
      <QuestionHeader question={question} />

      {answer?.data && answer?.isComplete && !runSpeedtest ? (
        <Row>
          <Col className="text-center" sm={12}>
            <h4 className="mt-3 mb-3">Speedtest Results</h4>
          </Col>
          <Col className="mb-4" md={12}>
            <Card className="speedtest-result shadow-sm border-0 mx-auto">
              <Card.Body className="d-flex align-items-center">
                <div className="circle-icon d-flex align-items-center justify-content-center ms-5 me-4">
                  <span className="material-symbols-outlined" style={{ fontSize: "2.5rem" }}>
                    download
                  </span>
                </div>
                <div className="result-data">
                  <h5 className="mb-0">Download</h5>
                  <p className="fs-1 fw-bolder mb-0">
                    {kbpsToMbps(answer.data.download)} <span className="fs-5 fw-normal">Mbps</span>
                  </p>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col className="mb-4" md={12}>
            <Card className="speedtest-result shadow-sm border-0 mx-auto">
              <Card.Body className="d-flex align-items-center">
                <div className="circle-icon d-flex align-items-center justify-content-center ms-5 me-4">
                  <span className="material-symbols-outlined" style={{ fontSize: "2.5rem" }}>
                    upload
                  </span>
                </div>
                <div className="result-data">
                  <h5 className="mb-0">Upload</h5>
                  <p className="fs-1 fw-bolder mb-0">
                    {kbpsToMbps(answer.data.upload)} <span className="fs-5 fw-normal">Mbps</span>
                  </p>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <p className="text-center mt-3">Want to test your internet speed again?</p>
          <button
            className="app-btn-secondary-inverted btn mx-auto"
            style={{ maxWidth: "300px" }}
            type="button"
            onClick={() => setRunSpeedtest(true)}
          >
            Restart Speedtest
          </button>
        </Row>
      ) : (
        <iframe
          width="100%"
          height="650px"
          frameBorder="0"
          src="https://activecomply.speedtestcustom.com"
          title="speed-test"
        ></iframe>
      )}
    </>
  );
}

export default SpeedTest;
