import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Start from "./components/Start";
import StartSurvey from "./components/StartSurvey";
import ProtectedRoutes from "./components/ProtectedRoutes";
import { AppContextProvider } from "./context/AppContext";

import "bootstrap/dist/css/bootstrap.css";
import "material-icons/iconfont/material-icons.css";
import "material-symbols";
import "@fontsource/open-sans";
import "@fontsource/open-sans/400-italic.css";
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/300-italic.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/500-italic.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/600-italic.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/700-italic.css";
import "./scss/App.scss";

//import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <AppContextProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/start" element={<Start />} />
          <Route path="/start/:surveyId" element={<StartSurvey />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/:surveyResponseId/:tokenId/:pageId" element={<App />} />
            <Route path="/:surveyResponseId/:tokenId/" element={<App />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AppContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
