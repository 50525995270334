import React, { useRef, useCallback, useState, useEffect } from "react";
import TextInput from "./inputs/TextInput";
import CheckboxInput from "./inputs/CheckboxInput";
import RadioInput from "./inputs/RadioInput";
import Textarea from "./inputs/TextareaInput";
import SelectInput from "./inputs/SelectInput";
import MultiSelectInput from "./inputs/MultiSelectInput";
import QuestionHeader from "./QuestionHeader";
import LoadingQuestion from "./LoadingQuestion";
import { autoId } from "../utils/StringHelpers";

function FormInputs(props) {
  const { setAnswerData, question, answer } = props;
  const [touched, setTouched] = useState("");
  let combinedAnswerData = useRef({});
  let combinedMetaData = useRef({});
  let inputsValid = useRef({});

  const [isAnswerLoaded, setIsAnswerLoaded] = useState(false);

  useEffect(() => {
    setIsAnswerLoaded(answer);
  }, [answer]);

  const areAllQuestionsAnswered = () => {
    let isComplete = true;
    Object.keys(inputsValid.current).forEach((fieldName) => {
      if (!inputsValid.current[fieldName]) {
        isComplete = false;
      }
    });

    return isComplete;
  };

  const setInputData = useCallback(
    (inputData) => {
      let fieldData = {};
      fieldData[inputData.id] = inputData.data;

      let metaData = {};

      if (inputData.meta) {
        metaData[inputData.id] = inputData.meta;
      }

      let localAnswerData = {
        ...combinedAnswerData.current,
        ...fieldData,
      };

      let localMetaData = {
        ...combinedMetaData.current,
        ...metaData,
      };

      inputsValid.current[inputData.id] = inputData.isValid;

      combinedAnswerData.current = localAnswerData;
      combinedMetaData.current = localMetaData;

      setAnswerData(
        question.id,
        {
          //screen: { width: window.screen.width, height: window.screen.height },
          //browser: { width: window.innerWidth, height: window.innerHeight },
          data: localAnswerData,
          meta: localMetaData,
          isComplete: areAllQuestionsAnswered(),
        },
        null,
        { collectClientData: true }
      );

      if (inputData.touched) {
        // This is for the dependsOn property of the surveyDef
        // This allows a child component to trigger a re-render so that any dependent
        // questions can re-bind with the new sibling values.
        setTouched(autoId());
      }
    },
    [question.id, setAnswerData]
  );

  const getComponent = (field, index) => {
    let inputFieldDef = question[field];

    let defaultValue = null;

    if (answer) {
      defaultValue = answer?.data && answer.data[field] ? answer.data[field] : null;
    }

    const focus = defaultValue === null && index === 0;

    let disabled = false;
    let hidden = false;

    if (inputFieldDef.dependsOn) {
      Object.keys(inputFieldDef.dependsOn).filter((key) => {
        const dependsOn = inputFieldDef.dependsOn[key];

        const currentValueOfDependent = combinedAnswerData.current[key];

        if (dependsOn.value !== currentValueOfDependent) {
          if (dependsOn.actionOnFail === "disable") {
            disabled = true;
          } else {
            hidden = true;
          }
          return true;
        } else {
          return false;
        }
      });
    }

    if (hidden) {
      inputsValid.current[field] = true;
    }

    let inputProps = {
      inputFieldId: field,
      inputFieldDef: inputFieldDef,
      inputDefaultValue: defaultValue,
      setInputData: setInputData,
      disabled: disabled,
      hidden: hidden,
      key: field, // react complains without this, required when using .map() method
    };

    let components = {
      text: <TextInput {...inputProps} focus={focus} />,
      email: <TextInput {...inputProps} focus={focus} />,
      number: <TextInput {...inputProps} focus={focus} />,
      tel: <TextInput {...inputProps} focus={focus} />,
      date: <TextInput {...inputProps} focus={focus} />,
      textarea: <Textarea {...inputProps} focus={focus} />,
      checkbox: <CheckboxInput {...inputProps} />,
      radio: <RadioInput {...inputProps} />,
      select: <SelectInput {...inputProps} />,
      multiselect: <MultiSelectInput {...inputProps} />,
    };

    return !inputProps.hidden ? (
      <div className="form-question" key={index}>
        {components[inputFieldDef.type]}
      </div>
    ) : null;
  };

  if (isAnswerLoaded) {
    return (
      <div className="form-inputs mx-auto">
        <QuestionHeader question={question} />

        <div className="form-input-group bg-white shadow-sm w-100 p-2 p-md-5 mt-4 mx-auto">
          {question.fieldOrder.map((field, index) => {
            return getComponent(field, index);
          })}
        </div>
      </div>
    );
  } else {
    return <LoadingQuestion />;
  }
}

export default FormInputs;
