import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { isMobile } from "react-device-detect";
import EmailLinkModal from "./EmailLinkModal";

function Info(props) {
  const { question } = props;
  const deviceType = question.deviceType;

  const emailLink = `${window.location.origin}${window.location.pathname}?linked=true`;
  const [showEmailModal, setEmailModal] = useState(false);


  if (!isMobile || (isMobile && deviceType !== "desktop")) {
    return (
      <div className="card question-info border-0 shadow shadow-sm w-100 mx-auto"
      style={{
        maxWidth: "750px"
      }}
      >
        <div className="card-body text-center p-4">
          {question.text.title ? (
            // <Alert className="w-100 text-center bg-light fs-2 fw-bolder">
            //   {question.text.title}
            // </Alert>
            <h1 className="text-center mb-4">{question.text.title}</h1>
          ) : null}

          {question?.images?.default ? (
            <img
            className="mb-4"
            src={question.images.default}
            alt="question"
            style={{
              width: "100%",
              height: "auto",
              maxWidth: "450px"
            }}
          />
          ) : null}

          {question.text.explain && (
            <ReactMarkdown className="markdown text-center" linkTarget="_blank">{question.text.explain}</ReactMarkdown>
          )}
        </div>
      </div>

    )
  }

  if (isMobile && deviceType === "desktop") {
    return (
      <>
        <h1 className="text-center mt-4 mb-4">Please open this survey on your desktop computer</h1>
        <div className="text-center mt-5">
          <svg width="157" height="157" viewBox="0 0 157 157" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1704_196703)">
              <path d="M147.189 157H94.8555C89.4366 157 85.043 152.606 85.043 147.187V62.146C85.043 56.7259 89.4366 52.3335 94.8555 52.3335H147.189C152.608 52.3335 157.002 56.7259 157.002 62.146V147.187C157.002 152.606 152.608 157 147.189 157ZM94.8555 58.8748C93.0492 58.8748 91.5854 60.3397 91.5854 62.146V147.187C91.5854 148.994 93.0492 150.458 94.8555 150.458H147.189C148.996 150.458 150.46 148.994 150.46 147.187V62.146C150.46 60.3397 148.996 58.8748 147.189 58.8748H94.8555Z" fill="#568DFF"/>
              <path d="M114.48 137.375H127.564V143.916H114.48V137.375Z" fill="#568DFF"/>
              <path d="M78.5 111.209H9.8125C4.39359 111.209 0 106.815 0 101.396V9.8125C0 4.39359 4.39359 0 9.8125 0H127.563C132.981 0 137.375 4.39359 137.375 9.8125V45.7913H130.834V9.8125C130.834 8.0062 129.369 6.54127 127.563 6.54127H9.8125C8.0062 6.54127 6.54127 8.0062 6.54127 9.8125V101.396C6.54127 103.203 8.0062 104.666 9.8125 104.666H78.5V111.209Z" fill="#568DFF"/>
              <path d="M39.25 107.938H45.7913V124.291H39.25V107.938Z" fill="#568DFF"/>
              <path d="M29.4375 121.021H78.5V127.562H29.4375V121.021Z" fill="#568DFF"/>
              <path d="M19.6233 29.4373H13.082V22.896C13.082 17.4759 17.4744 13.0835 22.8945 13.0835H29.4358V19.6248H22.8945C21.0882 19.6248 19.6233 21.0897 19.6233 22.896V29.4373Z" fill="#568DFF"/>
              <path d="M13.082 35.979H19.6233V42.5215H13.082V35.979Z" fill="#568DFF"/>
              <path d="M137.375 65.4165H143.916V71.959H137.375V65.4165Z" fill="#568DFF"/>
              <path d="M137.375 78.5H143.916V85.0413H137.375V78.5Z" fill="#568DFF"/>
              <path d="M124.293 65.4165H130.835V71.959H124.293V65.4165Z" fill="#568DFF"/>
              <path d="M114.478 45.7913H107.937V32.7087C107.937 30.9024 106.472 29.4375 104.666 29.4375H71.9583C70.6347 29.4375 69.4429 28.6398 68.9362 27.418C68.4307 26.1962 68.7098 24.79 69.6453 23.8545L79.4578 14.042L84.0826 18.6668L79.8531 22.8962H104.666C110.086 22.8962 114.478 27.2886 114.478 32.7087V45.7913Z" fill="#568DFF"/>
              <path d="M69.6445 28.4783L74.2705 23.8535L84.0842 33.666L79.4582 38.292L69.6445 28.4783Z" fill="#568DFF"/>
              <path d="M67.7276 97.167L63.1028 92.5422L67.3323 88.3127H42.5195C37.0994 88.3127 32.707 83.9192 32.707 78.5002V65.4165H39.2483V78.5002C39.2483 80.3065 40.7132 81.7715 42.5195 81.7715H75.2271C76.5507 81.7715 77.7425 82.568 78.2492 83.7898C78.7546 85.0128 78.4755 86.419 77.5401 87.3545L67.7276 97.167Z" fill="#568DFF"/>
              <path d="M63.1055 77.5439L67.7314 72.918L77.5439 82.7317L72.9192 87.3564L63.1055 77.5439Z" fill="#568DFF"/>
              <path d="M3.26953 81.771H26.1646V88.3123H3.26953V81.771Z" fill="#568DFF"/>
            </g>
            <defs>
              <clipPath id="clip0_1704_196703">
                <rect width="157" height="157" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="text-center">
          <button type="button" className="app-btn-secondary btn mt-5" onClick={() => setEmailModal(true)}>
            Email me the link
          </button>
        </div>


        <EmailLinkModal
          deviceType={deviceType}
          emailData={{
            subject: 'Survey link',
            html: `
              <div>Open the link below on your desktop computer.</div>
              <a href="${emailLink}">Continue</a>
            `
          }}
          showModal={showEmailModal}
          toggleModal={() => setEmailModal(false)}
        />


      </>
    )
  }

  return null;
}

export default Info;
